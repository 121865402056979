module.exports = {
  mode: "jit",
  purge: ["./src/**/*.html", "./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    fontFamily: {
      frutiger: "frutiger",
      obligatov: "obligatov",
    },
    colors: {
      white: "#ffffff",
      black: "#000000",
      gray: "#595959",
      alpha: "#37348b",
      beta: "#29b8ce",
    },
    extend: {
      strokeWidth: {
        3: "3",
        4: "4",
        5: "5",
        6: "6",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
};
