import { useDispatch, useSelector } from "react-redux";
import { getTextGradientStyle } from "../lib/getTextGradientStyle";
import { selectCurrentLanguage } from "../state/selectors/general";
import { setLanguage } from "../state/slices/general";

function Button({ currLang, languageId, onClick, className, children }) {
  const active = currLang === languageId;
  const activeStyle = getTextGradientStyle();

  return (
    <div
      className={`w-1/2 ${className} text-white font-obligatov text-2xl`}
      style={active ? activeStyle : null}
      onClick={() => onClick(languageId)}
    >
      {children}
    </div>
  );
}

export default function LanguageButton() {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectCurrentLanguage);

  const handleLanguageButtonClick = (language) => {
    dispatch(setLanguage(language));
  };

  return (
    <div className="absolute right-0 bottom-0 flex flex-row m-4 z-10">
      <Button
        className="pr-1"
        languageId="de"
        onClick={() => handleLanguageButtonClick("de")}
        currLang={currentLanguage}
      >
        de
      </Button>

      <Button
        className="pl-1"
        languageId="en"
        onClick={() => handleLanguageButtonClick("en")}
        currLang={currentLanguage}
      >
        en
      </Button>
    </div>
  );
}
