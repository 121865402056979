import { Gradient } from "./Gradient";

export const Arrow = ({ className, direction = "left" }) => {
  const gradientId = "grad-arrow";
  const scale = {
    left: "scale(1,1)",
    right: "scale(-1,1)",
  };

  return (
    <svg
      viewBox="0 0 1000 1000"
      className={className}
      style={{
        transform: scale[direction],
      }}
    >
      <defs>
        <Gradient id={gradientId} />
      </defs>
      <g>
        <path
          fill={`url(#${gradientId})`}
          d="M194.29,610.71v-225C406.98,385.71,580,212.69,580,0h225c0,205.65-101.63,387.54-257.43,498.21
		C703.37,608.88,805,790.77,805,996.42H580C580,783.73,406.98,610.71,194.29,610.71z"
        />
      </g>
    </svg>
  );
};
