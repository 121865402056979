import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { selectArticleItems } from "../state/selectors/articles";
import { selectFullscreenElement } from "../state/selectors/fullscreen";
import { selectDataLoadingState } from "../state/selectors/general";
import Image from "./Article/Image";
import Video from "./Article/Video";
import LoadingScreen from "./Loading/LoadingScreen";
import ArticleCarousel from "./ArticleCarousel";
import FullscreenOverlay from "./FullscreenOverlay";
import LanguageButton from "./LanguageButton";

export default function App() {
  const dataLoadingState = useSelector(selectDataLoadingState);
  const articles = useSelector(selectArticleItems);
  const fullscreenElement = useSelector(selectFullscreenElement);

  return (
    <div className="w-full h-full bg-black text-white overflow-hidden">
      {dataLoadingState !== "pending" ? (
        articles.length > 0 && (
          <>
            <ArticleCarousel />
            <LanguageButton />
            <AnimatePresence>
              {fullscreenElement.type && (
                <FullscreenOverlay>
                  {fullscreenElement.type === "image" && (
                    <Image
                      file={{ url: fullscreenElement.url }}
                      caption={fullscreenElement.caption}
                      imageWidth="100%"
                      className="justify-center"
                    />
                  )}
                  {fullscreenElement.type === "video" && (
                    <Video
                      url={fullscreenElement.url}
                      textTrackUrl={fullscreenElement.textTrackUrl}
                    />
                  )}
                </FullscreenOverlay>
              )}
            </AnimatePresence>
          </>
        )
      ) : (
        <LoadingScreen backgroundColor="black" color="white" />
      )}
    </div>
  );
}
