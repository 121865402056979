import PlayButton from "../../assets/icons/play-button.svg";
import Image from "./Image";

export default function VideoPlaceholder({ file, title, onClick }) {
  return (
    <div className="module">
      <div className="mb-3">{title}</div>
      <div className="relative">
        <div style={{ opacity: 0.6 }} onClick={onClick} className="w-full">
          {file ? (
            <Image file={file} />
          ) : (
            <div className="bg-gray w-full" style={{ height: 500 }} />
          )}
        </div>
        <PlayButton
          className="play-button w-24 absolute top-1/2 left-1/2 pointer-events-none"
          style={{
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    </div>
  );
}
