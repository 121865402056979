import { createSelector } from "@reduxjs/toolkit";

export const selectArticleItems = (state) => state.articles.items;
export const selectCurrentArticleId = (state) => state.articles.current;

export const selectCurrentArticleIndex = createSelector(
  selectArticleItems,
  selectCurrentArticleId,
  (items, id) => {
    const index = items.findIndex((i) => i.id === id);

    if (index > -1) return index;

    return items.findIndex((i) => i.id_en === id);
  },
);

export const selectArticleById = createSelector(
  selectArticleItems,
  (_, id) => id,
  (items, id) => {
    const item = items.find((i) => i.id === id);

    if (item) return item;

    return items.find((i) => i.id_en === id);
  },
);
