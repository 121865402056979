const getBuffer = async (audioContext, trackPath) => {
  //Get a file's beffer and decode it as audio
  const response = await fetch(trackPath);
  const arrayBuffer = await response.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  return audioBuffer;
};

const init = (trackPaths) => {
  let audioContext;
  let volume = 0.75;

  const buffers = {};
  const gainNodes = {};
  const sourceNodes = {};
  const trackIds = Object.keys(trackPaths);
  let playing = false;

  const loadBuffers = async () => {
    trackIds.forEach((trackId) => {
      const trackUrl = trackPaths[trackId];

      getBuffer(audioContext, trackUrl).then((buffer) => {
        buffers[trackId] = buffer;
      });
    });
  };

  const createGainNodes = () => {
    trackIds.forEach((trackId) => {
      const gainNode = audioContext.createGain();

      gainNode.connect(audioContext.destination);
      gainNode.gain.value = 0;

      gainNodes[trackId] = gainNode;
    });
  };

  const setup = async () => {
    try {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
    } catch (error) {
      console.warn(`Something went wrong with the Web Audio API`);
    }

    if (audioContext !== undefined) {
      await loadBuffers();
      createGainNodes();
    } else {
      console.warn("Tried to init audio engine with no tracks");
    }
  };

  setup();

  const create = (id) => {
    const source = audioContext.createBufferSource();
    const gain = gainNodes[id];

    source.buffer = buffers[id];
    // source.loop = true;
    source.connect(gain);

    sourceNodes[id] = source;
  };

  const play = (id) => {
    sourceNodes[id].start();
  };

  const playAll = () => {
    Object.keys(trackPaths).forEach((trackId, index) => {
      create(trackId);
      if (index == 0) {
        sourceNodes[trackId].onended = audioFinishedHandler;
      }
      play(trackId);
    });
    playing = true;
  };

  const silenceAll = () => {
    if (playing) {
      var fadeTime = 0.5;
      trackIds.forEach((trackId) => {
        if (gainNodes[trackId].gain !== 0) {
          const ramptime = sourceNodes[trackId].context.currentTime + fadeTime;
          gainNodes[trackId].gain.linearRampToValueAtTime(0.0001, ramptime);
        }
      });
    }
  };

  const setVolume = (value) => {
    volume = value;
    if (playing) {
      trackIds.forEach((trackId) => {
        if (gainNodes[trackId].gain.value > 0.01) {
          gainNodes[trackId].gain.value = volume;
        }
      });
    }
  };

  const select = (id) => {
    if (!playing) {
      playAll();
    }

    trackIds.forEach((trackId) => {
      const gainNode = gainNodes[trackId];
      if (trackId === id) {
        gainNode.gain.value = volume;
      } else {
        gainNode.gain.value = 0;
      }
    });
  };

  const audioFinishedHandler = (event) => {
    // On track 1 end, stopall and reinit to keep everything in sync
    console.log("audioFinished : ", event);
    playAll();
  };

  const destroy = () => {
    audioContext.close();
  };

  return {
    play,
    playAll,
    silenceAll,
    setVolume,
    select,
    destroy,
  };
};

export const audioEngine = (trackPaths) => {
  return init(trackPaths);
};
