import { useRef } from "react";
import { motion } from "framer-motion";

export default function Chart({
  chartData,
  chartStyle,
  currentCodec,
  handleSelect,
}) {
  const containerRef = useRef();

  const boundary = 40;
  const workingHeight = parseFloat(chartStyle.height) - 2 * boundary;
  const pxPerUnit = workingHeight / chartStyle.max;
  const guideStepUnits =
    (chartStyle.max - chartStyle.min) / chartStyle.guideCount;
  const guideStepPx = guideStepUnits * pxPerUnit;
  //const height = column.value * pxPerUnit;

  var guides = [];
  for (let i = 0; i <= chartStyle.guideCount; i++) {
    guides.push(chartStyle.min + guideStepUnits * i);
  }

  return (
    <div ref={containerRef} transition={{ ease: "easeInOut", duration: 1 }}>
      <svg
        width="100%"
        height={`${chartStyle.height}px`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id="gradient1"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
            gradientTransform="rotate(90)"
          >
            <stop offset="0%" stopColor="#29b7cd" />
            <stop offset="100%" stopColor="#333282" />
          </linearGradient>
        </defs>
        <foreignObject x={0} y={0} width={50} height={30}>
          <div
            style={{
              color: chartStyle.guideColor,
              fontSize: chartStyle.lableSize,
            }}
          >
            {chartData.unit}
          </div>
        </foreignObject>
        {guides.map((element, index) => {
          const rowY = chartStyle.height - guideStepPx * index - boundary;
          return (
            <g key={index}>
              <foreignObject
                key={`chartHorizGuideLables_` + index}
                x={0}
                y={rowY - parseFloat(chartStyle.lableSize) / 2 - 5}
                width={50}
                height={30}
              >
                <div
                  style={{
                    color: chartStyle.guideColor,
                    fontSize: chartStyle.lableSize,
                    textAlign: "right",
                  }}
                >
                  {element}
                </div>
              </foreignObject>

              <line
                key={`chartHorizGuides_` + index}
                x1={60}
                y1={rowY}
                x2="100%"
                y2={rowY}
                stroke={chartStyle.guideColor}
                strokeWidth={chartStyle.lineWidth}
                strokeDasharray={index == 0 ? "" : "10 20"}
                strokeLinecap="round"
              />
            </g>
          );
        })}

        {Object.keys(chartData.bars).map((key, index) => {
          const column = chartData.bars[key];
          console.log("column : ", column);
          const barCount = Object.keys(chartData.bars).length;
          let chartWidth = 900;
          if (containerRef.current) {
            chartWidth = containerRef.current.offsetWidth;
          }
          const offset = chartWidth * 0.1;
          const gapWidth = chartWidth * 0.05;
          const coloumnWidth =
            (chartWidth - offset - gapWidth * barCount) / barCount;
          const y = chartStyle.height - column.value * pxPerUnit - boundary;

          const transition = {
            delay: 0.75,
            duration: 1.5,
            ease: "easeInOut",
          };

          return (
            <g key={index}>
              <foreignObject
                x={offset + gapWidth * index + coloumnWidth * index}
                width={coloumnWidth}
                height={chartStyle.height}
                key={`chartBarTitle_` + key}
              >
                <motion.div
                  initial={{
                    y: chartStyle.height - boundary - 30,
                  }}
                  animate={{
                    y:
                      chartStyle.height -
                      column.value * pxPerUnit -
                      boundary -
                      30 -
                      column.vertical_offset,
                  }}
                  transition={transition}
                  style={{
                    opacity: key === currentCodec ? 1 : 0.5,
                    color: chartStyle.lableColor,
                    fontSize: chartStyle.lableSize,
                    textAlign: "center",
                  }}
                  onClick={() => {
                    handleSelect(key);
                  }}
                >
                  {column.lable}
                </motion.div>
              </foreignObject>
              <motion.rect
                initial={{
                  y: chartStyle.height - boundary,
                  height: 0,
                }}
                animate={{
                  y: y,
                  height: column.value * pxPerUnit,
                }}
                transition={transition}
                key={`chartBarBody_` + key}
                opacity={key === currentCodec ? 1 : 0.5}
                x={offset + gapWidth * index + coloumnWidth * index}
                width={coloumnWidth}
                stroke="#29b7cd"
                strokeWidth={2}
                fill="url(#gradient1)"
                onClick={() => {
                  handleSelect(key);
                }}
              />
            </g>
          );
        })}
      </svg>
      {chartStyle.buttons !== undefined && (
        <div className={`flex flex-row justify-around w-[97%] h-1/4 pl-[7%]`}>
          {Object.keys(chartData.bars).map((key) => {
            const fade = { duration: 0.3 };
            return (
              <div
                key={"button_" + key}
                onClick={() => {
                  handleSelect(key);
                }}
              >
                <motion.div
                  className={`absolute`}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: currentCodec !== key ? 1 : 0,
                  }}
                  transition={fade}
                >
                  <img src={chartStyle.buttons.active}></img>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: currentCodec === key ? 1 : 0,
                  }}
                  transition={fade}
                >
                  <img src={chartStyle.buttons.inactive}></img>
                </motion.div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
