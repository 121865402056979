import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { getAppData } from "../../lib/api";
import { initialLanguageFromPath } from "../../lib/config";
import {
  addEnIdToDeEntries,
  getCollectionWithTranslatedFields,
} from "../../lib/statamic";
import { setArticleItems, setCurrentArticle } from "./articles";

const { actions, reducer } = createSlice({
  name: "general",
  initialState: {
    language: initialLanguageFromPath || "de",
    idleMode: false,
    data: [],
    dataLoadingState: "idle",
  },
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
    enterIdleMode(state) {
      state.idleMode = true;
    },
    exitIdleMode(state) {
      state.idleMode = false;
    },
    dataLoading(state) {
      state.dataLoadingState = "pending";
    },
    dataReceived(state, action) {
      if (state.dataLoadingState === "pending") {
        state.dataLoadingState = "idle";
        state.data = action.payload;
      }
    },
  },
});

export const {
  setLanguage,
  enterIdleMode,
  exitIdleMode,
  dataLoading,
  dataReceived,
} = actions;

export const fetchData = () => async (dispatch, getState) => {
  dispatch(dataLoading());
  const data = await getAppData();
  dispatch(dataReceived(data.data));

  const articlesData = cloneDeep(data.data.articles.data);
  addEnIdToDeEntries(articlesData);
  const articles = getCollectionWithTranslatedFields(articlesData, [
    "title",
    "intro",
    "article",
    "qr_code",
  ]);
  dispatch(setArticleItems(articles));

  const currentArticle = getState().articles.current;

  if (currentArticle === null) {
    dispatch(setCurrentArticle(articles[0].id));
  }
};

export default reducer;
