import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { audioEngine } from "../../lib/audioEngine";
import config from "../../lib/config";
import { getFullAssetUrl } from "../../lib/statamic";
import Chart from "./Chart";
import Intro from "./Intro";
import VolumeVertical from "./VolumeVertical";
import "plyr/dist/plyr.css";

const { width } = config;

const stagger = 0.07;
const delay = 0.3;
const transition = {
  type: "spring",
  stiffness: 250,
  damping: 30,
};

const containerVariants = {
  enter: {
    transition: {
      staggerChildren: stagger,
    },
  },
  center: {
    transition: {
      staggerChildren: stagger,
      delayChildren: delay,
    },
  },
  exit: {
    transition: {
      staggerChildren: stagger,
    },
  },
};

const elementVariants = {
  enter: ({ direction }) => {
    return {
      x: direction > 0 ? width : -width,
      transition,
    };
  },
  center: {
    x: 0,
    transition,
  },
  exit: ({ direction }) => {
    return {
      x: direction < 0 ? width : -width,
      transition,
    };
  },
};

export default function CodecVergleich({ articleID, data, direction }) {
  const [currentCodec, setCurrentCodec] = useState("");
  const [volume, setVolume] = useState(0.75);
  const articleCurrent = useSelector((state) => state.articles.current);

  const audioEngineRef = useRef();

  const handleSelect = (id) => {
    //if track is already selected pause / silence
    if (currentCodec === id) {
      audioEngineRef.current.silenceAll();
      setCurrentCodec("");
    } else {
      setCurrentCodec(id);
    }
  };

  useEffect(() => {
    //When article changes (page exit start) fade audio
    if (audioEngineRef.current !== undefined && articleCurrent != articleID) {
      audioEngineRef.current.silenceAll();
    }
  }, [articleCurrent]);

  useEffect(() => {
    const tracklist = {};
    for (let i = 0; i < Object.keys(data).length; i++) {
      const key = Object.keys(data)[i];
      if (key.search("header_") != -1) {
        const codec = key.substring(7);
        tracklist[codec] = getFullAssetUrl(data[codec + "_audio_file"][0].url);
      }
    }
    audioEngineRef.current = audioEngine(tracklist);

    return () => {
      audioEngineRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (currentCodec !== "") {
      audioEngineRef.current.select(currentCodec);
    }
  }, [currentCodec]);

  const subtitile =
    data[(currentCodec === "" ? "pcm" : currentCodec) + "_subtitle"] || "";
  const body =
    data[(currentCodec === "" ? "pcm" : currentCodec) + "_body"] || "";

  const chartData = {
    unit: data.chartStyle_units,
    bars: {},
  };
  for (let i = 0; i < data.chart_data.length; i++) {
    const bar = data.chart_data[i];
    if (bar.id !== undefined) {
      chartData.bars[bar.id] = {
        lable: bar.label,
        value: parseFloat(bar.col_value),
        vertical_offset: bar.vertical_offset,
      };
    }
  }
  return (
    <motion.div
      variants={containerVariants}
      initial="enter"
      animate="center"
      exit="exit"
      className="relative"
    >
      <motion.div
        variants={elementVariants}
        custom={{ direction }}
        style={{
          backgroundColor: "#0008",
          color: "#666",
          position: "absolute",
          top: 50,
          right: 50,
          padding: 20,
          width: 450,
        }}
      >
        {data.tooltip}
      </motion.div>
      <motion.div
        key={`${articleID}_codecVegleich_chart`}
        variants={elementVariants}
        custom={{ direction }}
      >
        <div
          style={{ position: "relative" }}
          className={`flex justify-between`}
        >
          <div className={`flex-grow`}>
            <Chart
              chartData={chartData}
              currentCodec={currentCodec}
              handleSelect={handleSelect}
              chartStyle={{
                height: data.chartStyle_height,
                max: data.chartStyle_max,
                min: data.chartStyle_min,
                guideCount: data.chartStyle_guide_count,
                guideColor: data.chartStyle_guide_color,
                guideThickness: data.chartStyle_guide_thickness + "px",
                lineWidth: 2,
                lableColor: data.chartStyle_label_color,
                lableSize: data.chartStyle_label_size + "px",
                buttons: {
                  inactive: getFullAssetUrl(data.button_pause[0].url),
                  active: getFullAssetUrl(data.button_play[0].url),
                },
              }}
            />
          </div>
          <VolumeVertical
            value={volume}
            sliderStyle={{
              width: 30,
              height: 270,
              borderColor: data.chartStyle_guide_color,
            }}
            onChange={(event) => {
              setVolume(event.target.value);
              audioEngineRef.current.setVolume(volume);
            }}
          />
        </div>
      </motion.div>

      <motion.div
        key={`${articleID}_codecVegleich_subtitle`}
        variants={elementVariants}
        custom={{ direction }}
      >
        <div style={{ paddingTop: "3%" }}></div>
        <Intro>{subtitile}</Intro>
      </motion.div>
      <motion.div
        key={`${articleID}_codecVegleich_body`}
        variants={elementVariants}
        custom={{ direction }}
        style={{ paddingTop: "3%" }}
        dangerouslySetInnerHTML={{ __html: body }}
      ></motion.div>
    </motion.div>
  );
}
