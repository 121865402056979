import { getFullAssetUrl } from "../../lib/statamic";
import Caption from "./Caption";

export default function Image({
  file,
  containerWidth = "100%",
  className,
  imageWidth,
  caption,
  onClick,
}) {
  if (!file) return null;

  const { url, width: srcWidth, height: srcHeight } = file;
  const src = getFullAssetUrl(url);
  const orientationHorziontal = srcWidth >= srcHeight;
  const width = imageWidth
    ? imageWidth
    : orientationHorziontal
    ? "100%"
    : "50%";

  const handleClick = () => {
    if (typeof onClick === "function") onClick();
  };

  return (
    <div
      className={`flex flex-col items-center  ${className}`}
      style={{ width: containerWidth }}
    >
      <img
        src={src}
        className="object-cover"
        onClick={handleClick}
        width={srcWidth}
        height={srcHeight}
        style={{
          width,
        }}
      />
      {caption && <Caption width={width}>{caption}</Caption>}
    </div>
  );
}
