import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import { closeFullscreen } from "../state/slices/fullscreen";
import { Close } from "./GradientIcons/Close";

export default function FullscreenOverlay({ children, duration = 0.5 }) {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeFullscreen());
  };

  const initEscListener = () => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") close();
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  };

  useEffect(initEscListener, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration }}
      className="absolute top-0 left-0 w-full h-full z-10 flex p-10"
      style={{
        background: "rgba(0,0,0,0.9)",
      }}
    >
      {children}
      <div className="absolute top-0 right-0 m-10" onClick={close}>
        <Close className="w-12" />
      </div>
    </motion.div>
  );
}
