/**
 * This file contains statamic specifig helper functions,
 * especially for aggregating data
 */

import { cloneDeep } from "lodash";
import config from "./config";

/**
 * This function groups the data by locale
 * De is default and we manually overwrite fields
 * that have a translation
 */
export const groupByLocale = (data) => {
  const entriesDe = data.filter((element) => element.locale === "default");
  const entriesEn = data.filter((element) => element.locale === "en");

  const result = {
    de: entriesDe,
    en: cloneDeep(entriesDe),
  };

  entriesEn.forEach((entry) => {
    const localeEntryInResultSet = result.en.find((e) => e.id === entry.origin);

    Object.keys(entry).forEach((key) => {
      const value = entry[key];

      if (value !== null) {
        // replace any field with the translated value
        localeEntryInResultSet[key] = value;
      }
    });
  });

  return result;
};

/**
 * This function takes in entries from a statamic collection and a list
 * of fields that are localized. It will return only the origin entries
 * but with translated fields.
 *
 * Each entry needs the fields 'origin' and 'locale'
 */
export const getCollectionWithTranslatedFields = (data, fields = []) => {
  const entriesDe = data.filter((element) => element.locale === "default");
  const entriesEn = data.filter((element) => element.locale === "en");

  return cloneDeep(entriesDe).map((entry) => {
    const englishEntry = entriesEn.find((e) => e.origin === entry.id);

    fields.forEach((field) => {
      entry[field] = {
        de: entry[field],
        en: englishEntry ? englishEntry[field] || entry[field] : entry[field],
      };
    });

    return entry;
  });
};

export const addEnIdToDeEntries = (data) => {
  const entriesDe = data.filter((element) => element.locale === "default");
  const entriesEn = data.filter((element) => element.locale === "en");

  return entriesDe.map((entry) => {
    const englishEntry = entriesEn.find((e) => e.origin === entry.id);

    if (englishEntry) entry.id_en = englishEntry.id;
  });
};

export const getFullAssetUrl = (url) => `${config.cmsHost}${url}`;
