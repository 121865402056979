import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import tailwindConfig from "../../tailwind.config";
import {
  selectArticleItems,
  selectCurrentArticleId,
  selectCurrentArticleIndex,
} from "../state/selectors/articles";
import { selectTransitionDirection } from "../state/selectors/general";
import { setCurrentArticle } from "../state/slices/articles";
import Article from "./Article/Index";
import { Arrow } from "./GradientIcons/Arrow";

const { colors } = tailwindConfig.theme;

export default function ArticleCarousel() {
  const dispatch = useDispatch();
  const articles = useSelector(selectArticleItems);
  const currentArticleId = useSelector(selectCurrentArticleId);
  const currentArticleIndex = useSelector(selectCurrentArticleIndex);
  const direction = useSelector(selectTransitionDirection);

  const hasMoreThanOneSlide = articles.length > 1;
  const leftButtonActive = currentArticleIndex > 0 && hasMoreThanOneSlide;
  const rightButtonActive =
    currentArticleIndex < articles.length - 1 && hasMoreThanOneSlide;

  const handlePrevButtonClick = () => {
    dispatch(setCurrentArticle(articles[currentArticleIndex - 1].id));
  };

  const handleNextButtonClick = () => {
    dispatch(setCurrentArticle(articles[currentArticleIndex + 1].id));
  };

  return (
    <div className="w-full h-full relative">
      <SwipeButton
        className="left-0 ml-2"
        onClick={handlePrevButtonClick}
        color={colors.white}
      >
        {leftButtonActive && <Arrow />}
      </SwipeButton>
      <SwipeButton
        className="right-0 mr-2"
        onClick={handleNextButtonClick}
        color={colors.white}
      >
        {rightButtonActive && <Arrow direction="right" />}
      </SwipeButton>
      <AnimatePresence initial={false} custom={{ direction }}>
        {currentArticleId && (
          <Article
            id={currentArticleId}
            key={currentArticleId}
            direction={direction}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

function SwipeButton({ className, color, elRef, children, onClick }) {
  return (
    <div
      ref={elRef}
      style={{
        top: "50%",
        transform: "translate(0, -50%)",
        color,
      }}
      className={`w-10 absolute icon-stroke z-10 ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
