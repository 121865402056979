import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import config from "../../lib/config";
import { hasHeightOverflow } from "../../lib/helpers";
import { selectArticleById } from "../../state/selectors/articles";
import { selectCurrentLanguage } from "../../state/selectors/general";
import { setFullscreenElement } from "../../state/slices/fullscreen";
import CodecVergleich from "./CodecVergleich";
import Header from "./Header";
import Image from "./Image";
import Intro from "./Intro";
import QRCode from "./QRCode";
import VideoPlaceholder from "./VideoPlaceholder";

const { width } = config;

const stagger = 0.07;
const delay = 0.3;
const transition = {
  type: "spring",
  stiffness: 250,
  damping: 30,
};

const containerVariants = {
  enter: {
    transition: {
      staggerChildren: stagger,
    },
  },
  center: {
    transition: {
      staggerChildren: stagger,
      delayChildren: delay,
    },
  },
  exit: {
    transition: {
      staggerChildren: stagger,
    },
  },
};

const elementVariants = {
  enter: ({ direction }) => {
    return {
      x: direction > 0 ? width : -width,
      transition,
    };
  },
  center: {
    x: 0,
    transition,
  },
  exit: ({ direction }) => {
    return {
      x: direction < 0 ? width : -width,
      transition,
    };
  },
};

export default function Article({ id, direction }) {
  const dispatch = useDispatch();
  const articleRef = useRef();
  const [isOverflowing, setIsOverflowing] = useState(true);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const article = useSelector((state) => selectArticleById(state, id));

  const title = article.title[currentLanguage];
  const intro = article.intro[currentLanguage];
  const body = article.article[currentLanguage];
  const qrCode = article.qr_code[currentLanguage][0] ?? {};

  const handleVideoPlaceholderClick = (videoFile, subtitlesFile) => {
    if (!videoFile) return;

    const { url } = videoFile;
    const textTrackUrl = subtitlesFile ? subtitlesFile.url : null;

    dispatch(
      setFullscreenElement({
        type: "video",
        url,
        textTrackUrl,
      }),
    );
  };

  const handleImageClick = (imageFile, caption) => {
    if (!imageFile) return;

    const { url } = imageFile;

    dispatch(
      setFullscreenElement({
        type: "image",
        url,
        caption,
      }),
    );
  };

  useEffect(
    () => setIsOverflowing(hasHeightOverflow(articleRef.current)),
    [currentLanguage],
  );

  return (
    <motion.div
      variants={containerVariants}
      key={`${id}-container`}
      initial="enter"
      animate="center"
      exit="exit"
      className={`article ${isOverflowing && "gradient"}`}
      ref={articleRef}
    >
      <motion.div
        variants={elementVariants}
        key={`${id}-header`}
        custom={{ direction }}
      >
        <Header>{title}</Header>
      </motion.div>
      <motion.div
        variants={elementVariants}
        key={`${id}-intro`}
        custom={{ direction }}
      >
        <Intro>{intro}</Intro>
      </motion.div>
      {body.map((element, index) => {
        const { type } = element;
        const key = `${id}-${index}`;

        return (
          <motion.div
            variants={elementVariants}
            key={key}
            custom={{ direction }}
          >
            {type === "text" && (
              <div dangerouslySetInnerHTML={{ __html: element.text }} />
            )}
            {type === "image" && (
              <Image
                file={element.file}
                caption={element.caption}
                className="module"
                onClick={() => handleImageClick(element.file, element.caption)}
              />
            )}
            {type === "dual_image" && (
              <div className="flex flex-row justify-between">
                <Image
                  file={element.files[0]}
                  caption={element.caption_1}
                  imageWidth="100%"
                  containerWidth="47%"
                  className="module"
                  onClick={() =>
                    handleImageClick(element.files[0], element.caption_1)
                  }
                />
                <Image
                  file={element.files[1]}
                  caption={element.caption_2}
                  imageWidth="100%"
                  containerWidth="47%"
                  className="module"
                  onClick={() =>
                    handleImageClick(element.files[1], element.caption_2)
                  }
                />
              </div>
            )}
            {type === "video" && (
              <VideoPlaceholder
                title={element.title}
                file={element.placeholder_image}
                onClick={() =>
                  handleVideoPlaceholderClick(
                    element.file,
                    element.subtitles_file,
                  )
                }
              />
            )}
            {type === "codec_vergleich" && (
              <CodecVergleich
                articleID={id}
                data={element}
                direction={direction}
              />
            )}
          </motion.div>
        );
      })}
      {qrCode.url && (
        <motion.div
          variants={elementVariants}
          key={`${id}-qr`}
          custom={{ direction }}
        >
          <QRCode url={qrCode.url} description={qrCode.description} />
        </motion.div>
      )}
    </motion.div>
  );
}
