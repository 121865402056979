import QR from "qrcode.react";

export default function QRCode({ url, description }) {
  return (
    <div className="module flex flex-row items-start mt-10 w-3/4">
      <QR
        className=" h-full border-8 border-white mr-6"
        style={{}}
        value={url}
        renderAs="svg"
        size={300}
      />
      {description && (
        <div
          className="w-4/5"
          dangerouslySetInnerHTML={{
            __html: description.replace("\n", "<br>"),
          }}
        />
      )}
    </div>
  );
}
