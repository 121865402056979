import { useEffect, useRef } from "react";
import Plyr from "plyr";
import tailwindConfig from "../../../tailwind.config";
import { getFullAssetUrl } from "../../lib/statamic";
import "plyr/dist/plyr.css";

const { colors } = tailwindConfig.theme;

export default function Video({ url, textTrackUrl }) {
  const videoElementRef = useRef();
  const containerElementRef = useRef();
  const src = getFullAssetUrl(url);
  const textTrack = textTrackUrl ? getFullAssetUrl(textTrackUrl) : null;

  const initVideoPlayer = () => {
    const { style } = containerElementRef.current;

    style.setProperty("--plyr-color-main", colors.alpha);
    style.setProperty("--plyr-font-size-large", "23px");

    new Plyr(videoElementRef.current, {
      controls: ["play", "progress", "current-time", "mute", "volume"],
      autoplay: true,
      clickToPlay: true,
      disableContextMenu: false,
      resetOnEnd: true,
      captions: { active: true, language: "default", update: false },
      fullscreen: { enabled: false },
    });
  };

  useEffect(initVideoPlayer, []);

  return (
    <div
      className="flex flex-col items-center justify-center module w-full"
      ref={containerElementRef}
    >
      <video src={src} ref={videoElementRef} crossOrigin="anonymous">
        <track
          kind="captions"
          label="English captions"
          src={textTrack}
          srcLang="default"
        />
      </video>
    </div>
  );
}
