import { configureStore } from "@reduxjs/toolkit";
import loggerMiddleware from "./middleware/logger";
import articles from "./slices/articles";
import fullscreen from "./slices/fullscreen";
import general from "./slices/general";
import mqtt from "./slices/mqtt";

export const store = configureStore({
  reducer: { general, mqtt, articles, fullscreen },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});
