import { createSlice } from "@reduxjs/toolkit";
import { initialArticleFromPath } from "../../lib/config";

const getArticleIndex = (state, articleId) =>
  state.items.findIndex((article) => article.id === articleId);

const getTransitionDirection = (state, nextArticleId) => {
  const currentArticleIndex = getArticleIndex(state, state.current);
  const newArticleIndex = getArticleIndex(state, nextArticleId);

  return newArticleIndex > currentArticleIndex ? 1 : -1;
};

const { actions, reducer } = createSlice({
  name: "articles",
  initialState: {
    items: [],
    current: initialArticleFromPath || null,
    transitionDirection: 1,
  },
  reducers: {
    setArticleItems(state, action) {
      state.items = action.payload;
    },
    setCurrentArticle(state, action) {
      const nextItemId = action.payload;
      state.transitionDirection = getTransitionDirection(state, nextItemId);
      state.current = nextItemId;
    },
  },
  extraReducers: {
    "general/enterIdleMode": (state) => {
      const firstItemId = state.items[0]?.id;

      if (firstItemId) {
        state.transitionDirection = -1;
        state.current = firstItemId;
      }
    },
  },
});

export const { setArticleItems, setCurrentArticle } = actions;

export default reducer;
